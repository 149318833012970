/*
    Be aware that SponsoredOfferBox component relies heavily on the layout of this component.
    In case of making any change in layout, please also update SponsoredOfferBox

    TODO: Maybe it's worth to make some abstraction/shared component if there are more cases like this
 */
import React, {memo, useState} from "react";
import {useSelector} from "react-redux";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {p} from "@pg-design/helpers-css-strings";
import {OFFER_TEST_IDS} from "@pg-mono/e2e-test-utils";

import {IRPStore} from "../../../app/rp_reducer";
import {OfferBoxBase} from "../../../atoms/OfferBoxBase";
import {showPropertiesClick} from "../../../tracking/algolytics_hits/show_properties_click";
import {ILatestQuery} from "../../../utils/latest_query";
import {useOfferLink} from "../../detail/hooks/use_offer_link";
import {getOfferTypeNamePlural} from "../../helpers/OfferType";
import {useOfferBoxAnalytics} from "../../hooks/use_offer_box_analytics";
import {IOfferBoxOffer} from "../../types/IOfferBoxOffer";
import {createTrackedOffer} from "../../utils/create_tracked_offer";
import {NoTagPlug} from "./NoTagPlug";
import {OfferBoxInfoSectionExtended} from "./OfferBoxInfoSectionExtended";
import {OfferBoxTopSectionSlider} from "./OfferBoxTopSectionSlider";

export interface IOfferBox {
    isArchived?: boolean;
    offer: IOfferBoxOffer;
    onShowOfferDetailsBtnClick?: (offer: IOfferBoxOffer) => void;
    offerDetailsBtnText?: string;
    unlinkVendor?: boolean;
    disableFavInfoText?: boolean;
    className?: string;
    shouldLinkToOffer?: boolean;
    onClick?: () => void;
    index?: number;
    showFullPrice?: boolean;
    fetchPriority?: "high" | "low" | "auto";
    latestQuery?: ILatestQuery & Record<string, string | string[] | null>;
}

export const OfferBox = memo((props: IOfferBox) => {
    const [isInfoPanelOpened, setIsInfoPanelOpened] = useState(false);
    const {offer, isArchived, showFullPrice, fetchPriority} = props;
    const viewType = useSelector((state: IRPStore) => state.viewType.current);

    const offerLink = useOfferLink(props.offer);
    const {onPrevClick, onNextClick, reportOfferSelect} = useOfferBoxAnalytics({
        offerId: props.offer.id,
        offerBoxIndex: props.index || 0
    });

    const onInfoPanelToggleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setIsInfoPanelOpened(!isInfoPanelOpened);
        event.preventDefault();
    };
    const onInfoPanelCloseClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setIsInfoPanelOpened(false);
    };

    const onOfferLinkClick = () => {
        reportOfferSelect();
    };

    return (
        <OfferBoxBase data-id={offer.id} className={props.className} onClick={props.onClick}>
            <OfferBoxTopSectionSlider
                offer={offer}
                offerLink={offerLink}
                fetchPriority={fetchPriority}
                index={props.index}
                onInfoPanelToggleClick={onInfoPanelToggleClick}
                onPrevClick={onPrevClick}
                onNextClick={onNextClick}
                onOfferLinkClick={onOfferLinkClick}
            />

            <OfferBoxInfoSectionExtended
                offer={offer}
                offerLink={offerLink}
                isArchived={isArchived}
                unlinkVendor={props.unlinkVendor}
                showFullPrice={showFullPrice}
                latestQuery={props.latestQuery}
                isInfoPanelOpened={isInfoPanelOpened}
                onInfoPanelCloseClick={onInfoPanelCloseClick}
                onOfferLinkClick={onOfferLinkClick}
            />

            {props.onShowOfferDetailsBtnClick && !isArchived ? (
                <div css={buttonWrapperStyle}>
                    <Button
                        dataTestId={OFFER_TEST_IDS.LISTING_OFFER_BOX.PROPERTY_VIEW_BUTTON}
                        css={actionButtonStyle}
                        variant="filled_primary"
                        onClick={() => {
                            reportOfferSelect();

                            if (props.shouldLinkToOffer) {
                                return;
                            }
                            viewType != null && showPropertiesClick(viewType, createTrackedOffer(offer), props.offer.vendor);
                            props.onShowOfferDetailsBtnClick && props.onShowOfferDetailsBtnClick(props.offer);
                        }}
                        href={props.shouldLinkToOffer ? offerLink : undefined}
                        target={props.shouldLinkToOffer ? "_blank" : undefined}
                    >
                        {props.offerDetailsBtnText ?? `Zobacz ${getOfferTypeNamePlural(offer.type)}`}
                    </Button>
                </div>
            ) : (
                <NoTagPlug height="6.4rem" />
            )}
        </OfferBoxBase>
    );
});

const buttonWrapperStyle = css`
    ${p(0, 2, 2, 2)};
`;

const actionButtonStyle = css`
    width: 100%;
`;
/*
    Be aware that SponsoredOfferBox component relies heavily on the layout of this component.
    In case of making any change in layout, please also update SponsoredOfferBox

    TODO: Maybe it's worth to make some abstraction/shared component if there are more cases like this
 */
import React, {memo, useState} from "react";
import {useSelector} from "react-redux";
import {css} from "@emotion/react";

import {Button} from "@pg-design/button-module";
import {p} from "@pg-design/helpers-css-strings";
import {OFFER_TEST_IDS} from "@pg-mono/e2e-test-utils";

import {IRPStore} from "../../../app/rp_reducer";
import {OfferBoxBase} from "../../../atoms/OfferBoxBase";
import {showPropertiesClick} from "../../../tracking/algolytics_hits/show_properties_click";
import {ILatestQuery} from "../../../utils/latest_query";
import {useOfferLink} from "../../detail/hooks/use_offer_link";
import {getOfferTypeNamePlural} from "../../helpers/OfferType";
import {useOfferBoxAnalytics} from "../../hooks/use_offer_box_analytics";
import {IOfferBoxOffer} from "../../types/IOfferBoxOffer";
import {createTrackedOffer} from "../../utils/create_tracked_offer";
import {NoTagPlug} from "./NoTagPlug";
import {OfferBoxInfoSectionExtended} from "./OfferBoxInfoSectionExtended";
import {OfferBoxTopSectionSlider} from "./OfferBoxTopSectionSlider";

export interface IOfferBox {
    isArchived?: boolean;
    offer: IOfferBoxOffer;
    onShowOfferDetailsBtnClick?: (offer: IOfferBoxOffer) => void;
    offerDetailsBtnText?: string;
    unlinkVendor?: boolean;
    disableFavInfoText?: boolean;
    className?: string;
    shouldLinkToOffer?: boolean;
    onClick?: () => void;
    index?: number;
    showFullPrice?: boolean;
    fetchPriority?: "high" | "low" | "auto";
    latestQuery?: ILatestQuery & Record<string, string | string[] | null>;
}

export const OfferBox = memo((props: IOfferBox) => {
    const [isInfoPanelOpened, setIsInfoPanelOpened] = useState(false);
    const {offer, isArchived, showFullPrice, fetchPriority} = props;
    const viewType = useSelector((state: IRPStore) => state.viewType.current);

    const offerLink = useOfferLink(props.offer);
    const {onPrevClick, onNextClick, reportOfferSelect} = useOfferBoxAnalytics({
        offerId: props.offer.id,
        offerBoxIndex: props.index || 0
    });

    const onInfoPanelToggleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setIsInfoPanelOpened(!isInfoPanelOpened);
        event.preventDefault();
    };
    const onInfoPanelCloseClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setIsInfoPanelOpened(false);
    };

    const onOfferLinkClick = () => {
        reportOfferSelect();
    };

    return (
        <OfferBoxBase data-id={offer.id} className={props.className} onClick={props.onClick}>
            <OfferBoxTopSectionSlider
                offer={offer}
                offerLink={offerLink}
                fetchPriority={fetchPriority}
                index={props.index}
                onInfoPanelToggleClick={onInfoPanelToggleClick}
                onPrevClick={onPrevClick}
                onNextClick={onNextClick}
                onOfferLinkClick={onOfferLinkClick}
            />

            <OfferBoxInfoSectionExtended
                offer={offer}
                offerLink={offerLink}
                isArchived={isArchived}
                unlinkVendor={props.unlinkVendor}
                showFullPrice={showFullPrice}
                latestQuery={props.latestQuery}
                isInfoPanelOpened={isInfoPanelOpened}
                onInfoPanelCloseClick={onInfoPanelCloseClick}
                onOfferLinkClick={onOfferLinkClick}
            />

            {props.onShowOfferDetailsBtnClick && !isArchived ? (
                <div css={buttonWrapperStyle}>
                    <Button
                        dataTestId={OFFER_TEST_IDS.LISTING_OFFER_BOX.PROPERTY_VIEW_BUTTON}
                        css={actionButtonStyle}
                        variant="filled_primary"
                        onClick={() => {
                            reportOfferSelect();

                            if (props.shouldLinkToOffer) {
                                return;
                            }
                            viewType != null && showPropertiesClick(viewType, createTrackedOffer(offer), props.offer.vendor);
                            props.onShowOfferDetailsBtnClick && props.onShowOfferDetailsBtnClick(props.offer);
                        }}
                        href={props.shouldLinkToOffer ? offerLink : undefined}
                        target={props.shouldLinkToOffer ? "_blank" : undefined}
                    >
                        {props.offerDetailsBtnText ?? `Zobacz ${getOfferTypeNamePlural(offer.type)}`}
                    </Button>
                </div>
            ) : (
                <NoTagPlug height="6.4rem" />
            )}
        </OfferBoxBase>
    );
});

const buttonWrapperStyle = css`
    ${p(0, 2, 2, 2)};
`;

const actionButtonStyle = css`
    width: 100%;
`;
