import React from "react";
import {css} from "@emotion/react";

import {calculateRemSize, flex, flexAbsoluteCenter, flexDirection, maxWidth, mb, mt, p, truncate, w100} from "@pg-design/helpers-css-strings";
import {CalendarCheckIcon, MapMarkerOutlineIcon, PropertyPlanIcon} from "@pg-design/icons-module";
import {Highlight, Text} from "@pg-design/text-module";

import {useResponsiveLinkTarget} from "../../../hooks/use_responsive_link_target";
import {useResponsiveOpenLink} from "../../../hooks/use_responsive_open_link";
import {ILatestQuery} from "../../../utils/latest_query";
import {useOfferDetails} from "../../hooks/use_offer_details";
import {IOfferBoxOffer} from "../../types/IOfferBoxOffer";
import {NoTagPlug} from "./NoTagPlug";
import {OfferBoxPill} from "./OfferBoxPill";
import {OfferBoxVendorImage} from "./OfferBoxVendorImage";
import {OfferToggleDescription} from "./OfferToggleDescription";

interface IProps {
    offer: IOfferBoxOffer;
    offerLink: string;
    isArchived?: boolean;
    unlinkVendor?: boolean;
    showFullPrice?: boolean;
    isInfoPanelOpened?: boolean;
    latestQuery?: ILatestQuery & Record<string, string | string[] | null>;
    onInfoPanelCloseClick: (event: React.MouseEvent) => void;
    onOfferLinkClick?: () => void;
}

export const OfferBoxInfoSectionExtended = (props: IProps) => {
    const {offer, offerLink, isInfoPanelOpened, onInfoPanelCloseClick} = props;
    const target = useResponsiveLinkTarget();
    const openResponsiveOfferLink = useResponsiveOpenLink(offerLink);
    const {addressText, constructionEndDate, priceToShow, planText} = useOfferDetails(props);

    const handleOfferLinkClick = () => {
        props.onOfferLinkClick?.();
        openResponsiveOfferLink();
    };

    return (
        <div css={offerBoxWrapperStyle}>
            {offer.description && (
                <OfferToggleDescription show={!!offer.description && isInfoPanelOpened} truncateLine={8} onClose={onInfoPanelCloseClick}>
                    {offer.description}
                </OfferToggleDescription>
            )}

            <div css={headerStyle}>
                <div css={nameColumnStyle}>
                    <a href={offerLink} title={offer.name} target={target} onMouseDown={props.onOfferLinkClick} css={offerLinkStyle}>
                        <Text variant="headline_6" as="h2" css={offerNameStyle}>
                            {offer.name}
                        </Text>
                    </a>
                    <div onClick={handleOfferLinkClick}>
                        {priceToShow ? (
                            <Text variant="body_copy_1" as="div" strong>
                                <Highlight>od {priceToShow}</Highlight>
                            </Text>
                        ) : (
                            <NoTagPlug height="2rem" />
                        )}
                    </div>
                </div>

                <OfferBoxVendorImage
                    unlinkVendor={props.unlinkVendor}
                    vendorName={offer.vendor.name}
                    vendorSlug={offer.vendor.slug}
                    vendorId={offer.vendor.id}
                    vendorLogo={offer.vendor.logo?.v_log_80x60}
                    width="72px"
                    height="54px"
                />
            </div>

            <div css={offerBoxPillWrapperStyle}>
                <OfferBoxPill icon={CalendarCheckIcon} isArchived={props.isArchived} text={constructionEndDate} />
            </div>

            <div onClick={handleOfferLinkClick} css={offerDetailListStyle}>
                <div css={infoSectionWrapperStyle}>
                    <div css={offerDetailRecordStyle}>
                        <div css={iconWrapperStyle}>
                            <MapMarkerOutlineIcon size="1.6" />
                        </div>
                        <Text variant="info_txt_1" css={addressWrapperStyle}>
                            {addressText}
                        </Text>
                    </div>
                    <div css={offerDetailRecordStyle}>
                        <div css={iconWrapperStyle}>
                            <PropertyPlanIcon size="1.6" />
                        </div>
                        <Text variant="info_txt_1">{planText}</Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

const offerBoxWrapperStyle = css`
    ${p(2, 2, 0, 2)};
    position: relative;
`;

const headerStyle = css`
    ${flex("center", "space-between")};
    ${flexDirection("row")};
    ${w100};
    gap: ${calculateRemSize(2)};
`;

const nameColumnStyle = css`
    flex: 1 1 auto;
    overflow: hidden;
`;

const offerLinkStyle = css`
    &:hover {
        color: unset;
    }
`;

const iconWrapperStyle = css`
    flex: 0 0 ${calculateRemSize(3)};
    ${flexAbsoluteCenter};
    width: ${calculateRemSize(3)};
    height: ${calculateRemSize(3)};
`;

const offerDetailListStyle = css`
    ${flex()};
    flex-direction: column;
    height: ${calculateRemSize(8)};
`;

const offerDetailRecordStyle = css`
    ${flex("center", "flex-start")};
    gap: ${calculateRemSize(0.5)};
`;

const offerNameStyle = css`
    ${truncate};
`;

const offerBoxPillWrapperStyle = css`
    ${mt(2)};
    ${mb(2)};
`;

const infoSectionWrapperStyle = css`
    ${flex()};
    ${flexDirection("column")};
`;

const addressWrapperStyle = css`
    ${maxWidth("27rem")};
`;
import React from "react";
import {css} from "@emotion/react";

import {calculateRemSize, flex, flexAbsoluteCenter, flexDirection, maxWidth, mb, mt, p, truncate, w100} from "@pg-design/helpers-css-strings";
import {CalendarCheckIcon, MapMarkerOutlineIcon, PropertyPlanIcon} from "@pg-design/icons-module";
import {Highlight, Text} from "@pg-design/text-module";

import {useResponsiveLinkTarget} from "../../../hooks/use_responsive_link_target";
import {useResponsiveOpenLink} from "../../../hooks/use_responsive_open_link";
import {ILatestQuery} from "../../../utils/latest_query";
import {useOfferDetails} from "../../hooks/use_offer_details";
import {IOfferBoxOffer} from "../../types/IOfferBoxOffer";
import {NoTagPlug} from "./NoTagPlug";
import {OfferBoxPill} from "./OfferBoxPill";
import {OfferBoxVendorImage} from "./OfferBoxVendorImage";
import {OfferToggleDescription} from "./OfferToggleDescription";

interface IProps {
    offer: IOfferBoxOffer;
    offerLink: string;
    isArchived?: boolean;
    unlinkVendor?: boolean;
    showFullPrice?: boolean;
    isInfoPanelOpened?: boolean;
    latestQuery?: ILatestQuery & Record<string, string | string[] | null>;
    onInfoPanelCloseClick: (event: React.MouseEvent) => void;
    onOfferLinkClick?: () => void;
}

export const OfferBoxInfoSectionExtended = (props: IProps) => {
    const {offer, offerLink, isInfoPanelOpened, onInfoPanelCloseClick} = props;
    const target = useResponsiveLinkTarget();
    const openResponsiveOfferLink = useResponsiveOpenLink(offerLink);
    const {addressText, constructionEndDate, priceToShow, planText} = useOfferDetails(props);

    const handleOfferLinkClick = () => {
        props.onOfferLinkClick?.();
        openResponsiveOfferLink();
    };

    return (
        <div css={offerBoxWrapperStyle}>
            {offer.description && (
                <OfferToggleDescription show={!!offer.description && isInfoPanelOpened} truncateLine={8} onClose={onInfoPanelCloseClick}>
                    {offer.description}
                </OfferToggleDescription>
            )}

            <div css={headerStyle}>
                <div css={nameColumnStyle}>
                    <a href={offerLink} title={offer.name} target={target} onMouseDown={props.onOfferLinkClick} css={offerLinkStyle}>
                        <Text variant="headline_6" as="h2" css={offerNameStyle}>
                            {offer.name}
                        </Text>
                    </a>
                    <div onClick={handleOfferLinkClick}>
                        {priceToShow ? (
                            <Text variant="body_copy_1" as="div" strong>
                                <Highlight>od {priceToShow}</Highlight>
                            </Text>
                        ) : (
                            <NoTagPlug height="2rem" />
                        )}
                    </div>
                </div>

                <OfferBoxVendorImage
                    unlinkVendor={props.unlinkVendor}
                    vendorName={offer.vendor.name}
                    vendorSlug={offer.vendor.slug}
                    vendorId={offer.vendor.id}
                    vendorLogo={offer.vendor.logo?.v_log_80x60}
                    width="72px"
                    height="54px"
                />
            </div>

            <div css={offerBoxPillWrapperStyle}>
                <OfferBoxPill icon={CalendarCheckIcon} isArchived={props.isArchived} text={constructionEndDate} />
            </div>

            <div onClick={handleOfferLinkClick} css={offerDetailListStyle}>
                <div css={infoSectionWrapperStyle}>
                    <div css={offerDetailRecordStyle}>
                        <div css={iconWrapperStyle}>
                            <MapMarkerOutlineIcon size="1.6" />
                        </div>
                        <Text variant="info_txt_1" css={addressWrapperStyle}>
                            {addressText}
                        </Text>
                    </div>
                    <div css={offerDetailRecordStyle}>
                        <div css={iconWrapperStyle}>
                            <PropertyPlanIcon size="1.6" />
                        </div>
                        <Text variant="info_txt_1">{planText}</Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

const offerBoxWrapperStyle = css`
    ${p(2, 2, 0, 2)};
    position: relative;
`;

const headerStyle = css`
    ${flex("center", "space-between")};
    ${flexDirection("row")};
    ${w100};
    gap: ${calculateRemSize(2)};
`;

const nameColumnStyle = css`
    flex: 1 1 auto;
    overflow: hidden;
`;

const offerLinkStyle = css`
    &:hover {
        color: unset;
    }
`;

const iconWrapperStyle = css`
    flex: 0 0 ${calculateRemSize(3)};
    ${flexAbsoluteCenter};
    width: ${calculateRemSize(3)};
    height: ${calculateRemSize(3)};
`;

const offerDetailListStyle = css`
    ${flex()};
    flex-direction: column;
    height: ${calculateRemSize(8)};
`;

const offerDetailRecordStyle = css`
    ${flex("center", "flex-start")};
    gap: ${calculateRemSize(0.5)};
`;

const offerNameStyle = css`
    ${truncate};
`;

const offerBoxPillWrapperStyle = css`
    ${mt(2)};
    ${mb(2)};
`;

const infoSectionWrapperStyle = css`
    ${flex()};
    ${flexDirection("column")};
`;

const addressWrapperStyle = css`
    ${maxWidth("27rem")};
`;
