export const color = (color: string) => `
    color: ${color};
`;

export const backgroundColor = (color: string) => `
    background-color: ${color};
`;
export const color = (color: string) => `
    color: ${color};
`;

export const backgroundColor = (color: string) => `
    background-color: ${color};
`;
