import {css} from "@emotion/react";

import {Button, IButtonSizePrimary} from "@pg-design/button-module";
import {CheckIcon, EyeIcon} from "@pg-design/icons-module";
import {TextVariantUnionType} from "@pg-design/text";
import {Text} from "@pg-design/text-module";

import {useFavourites} from "../../favourite/hooks/use_favourites";
import {hitGtmAddToCompareClick} from "../../favourite/tracking/favourite_gtm_events";
import {gtmFavouriteAddToFavouritesClick} from "../../favourite/tracking/gtm_event_favourites_multilead";
import {handleFavouritesListCheckHit} from "../../tracking/algolytics_hits/favourites_list_hit";

interface IProps {
    offerId: number;
    propertyId?: number;
    size?: "s" | "m" | "l";
    className?: string;
}

export function SubscribeButton(props: IProps) {
    const {propertyId, offerId, size = "s", className} = props;

    /**
     * Currently it's based on favourites, because first we check this in AB as mvp.
     * In case AB's passed, move it to new implementation.
     */
    const {
        isOfferInFavourites,
        isPropertyInFavourites,
        addPropertyToFavourites,
        addOfferToFavourites,
        removePropertyFromFavourites,
        removeOfferFromFavourites
    } = useFavourites();

    const isSubscribed = propertyId ? isPropertyInFavourites(propertyId) : isOfferInFavourites(offerId);

    const onFavouriteToggle = async () => {
        const {offerId, propertyId} = props;

        if (propertyId && isSubscribed) {
            await removePropertyFromFavourites(propertyId);

            hitGtmAddToCompareClick(false);
            handleFavouritesListCheckHit(false, offerId, propertyId);
        } else if (!propertyId && isSubscribed) {
            await removeOfferFromFavourites(offerId);

            gtmFavouriteAddToFavouritesClick(false);
            handleFavouritesListCheckHit(false, offerId);
        } else if (propertyId && !isSubscribed) {
            await addPropertyToFavourites(propertyId, offerId);

            hitGtmAddToCompareClick(true);
            handleFavouritesListCheckHit(true, offerId, propertyId);
        } else {
            //  !propertyId && !isInFavourites
            await addOfferToFavourites(offerId);

            gtmFavouriteAddToFavouritesClick(true);
            handleFavouritesListCheckHit(true, offerId);
        }
    };

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onFavouriteToggle();
    };

    return (
        <Button
            className={className}
            css={subscribeButton}
            iconLeft={isSubscribed ? CheckIcon : EyeIcon}
            onClick={handleClick}
            size={getButtonSize(size)}
            variant={isSubscribed ? "filled_secondary" : "filled_primary"}
        >
            <Text variant={getButtonTextSize(size)}>{isSubscribed ? "Obserwujesz" : "Obserwuj"}</Text>
        </Button>
    );
}

// Styles
const subscribeButton = css`
    flex: 1 0 auto;
`;

// Utils
function getButtonSize(size: IProps["size"]): IButtonSizePrimary {
    if (size === "m") {
        return "small";
    }

    if (size === "l") {
        return "medium";
    }

    return "x-small";
}

function getButtonTextSize(size: IProps["size"]): TextVariantUnionType {
    if (size === "m") {
        return "button_small";
    }

    if (size === "l") {
        return "button_medium";
    }

    return "button_small";
}
import {css} from "@emotion/react";

import {Button, IButtonSizePrimary} from "@pg-design/button-module";
import {CheckIcon, EyeIcon} from "@pg-design/icons-module";
import {TextVariantUnionType} from "@pg-design/text";
import {Text} from "@pg-design/text-module";

import {useFavourites} from "../../favourite/hooks/use_favourites";
import {hitGtmAddToCompareClick} from "../../favourite/tracking/favourite_gtm_events";
import {gtmFavouriteAddToFavouritesClick} from "../../favourite/tracking/gtm_event_favourites_multilead";
import {handleFavouritesListCheckHit} from "../../tracking/algolytics_hits/favourites_list_hit";

interface IProps {
    offerId: number;
    propertyId?: number;
    size?: "s" | "m" | "l";
    className?: string;
}

export function SubscribeButton(props: IProps) {
    const {propertyId, offerId, size = "s", className} = props;

    /**
     * Currently it's based on favourites, because first we check this in AB as mvp.
     * In case AB's passed, move it to new implementation.
     */
    const {
        isOfferInFavourites,
        isPropertyInFavourites,
        addPropertyToFavourites,
        addOfferToFavourites,
        removePropertyFromFavourites,
        removeOfferFromFavourites
    } = useFavourites();

    const isSubscribed = propertyId ? isPropertyInFavourites(propertyId) : isOfferInFavourites(offerId);

    const onFavouriteToggle = async () => {
        const {offerId, propertyId} = props;

        if (propertyId && isSubscribed) {
            await removePropertyFromFavourites(propertyId);

            hitGtmAddToCompareClick(false);
            handleFavouritesListCheckHit(false, offerId, propertyId);
        } else if (!propertyId && isSubscribed) {
            await removeOfferFromFavourites(offerId);

            gtmFavouriteAddToFavouritesClick(false);
            handleFavouritesListCheckHit(false, offerId);
        } else if (propertyId && !isSubscribed) {
            await addPropertyToFavourites(propertyId, offerId);

            hitGtmAddToCompareClick(true);
            handleFavouritesListCheckHit(true, offerId, propertyId);
        } else {
            //  !propertyId && !isInFavourites
            await addOfferToFavourites(offerId);

            gtmFavouriteAddToFavouritesClick(true);
            handleFavouritesListCheckHit(true, offerId);
        }
    };

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onFavouriteToggle();
    };

    return (
        <Button
            className={className}
            css={subscribeButton}
            iconLeft={isSubscribed ? CheckIcon : EyeIcon}
            onClick={handleClick}
            size={getButtonSize(size)}
            variant={isSubscribed ? "filled_secondary" : "filled_primary"}
        >
            <Text variant={getButtonTextSize(size)}>{isSubscribed ? "Obserwujesz" : "Obserwuj"}</Text>
        </Button>
    );
}

// Styles
const subscribeButton = css`
    flex: 1 0 auto;
`;

// Utils
function getButtonSize(size: IProps["size"]): IButtonSizePrimary {
    if (size === "m") {
        return "small";
    }

    if (size === "l") {
        return "medium";
    }

    return "x-small";
}

function getButtonTextSize(size: IProps["size"]): TextVariantUnionType {
    if (size === "m") {
        return "button_small";
    }

    if (size === "l") {
        return "button_medium";
    }

    return "button_small";
}
