import {CSSProperties} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {elevation} from "@pg-design/elevation";
import {borderRadius} from "@pg-design/helpers-css";
import {getThemeBreakpoint} from "@pg-design/styles-strings";

export const OfferBoxBase = styled.div`
    ${elevation(2)}
    width: 100%;
    min-width: 288px;
    max-width: 375px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    transition: box-shadow 0.2s ease-in-out;
    ${borderRadius(2, 2, 2, 2)}

    &:hover {
        ${elevation(3)}
    }
`;

export const offerBoxImgWrapperStyle = css`
    height: 20.4rem;

    @media (min-width: ${getThemeBreakpoint().md}) {
        height: 24.6rem;
    }
`;

export const offerBoxImgStyle: CSSProperties = {
    objectFit: "cover",
    height: "100%",
    width: "100%"
};
import {CSSProperties} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {elevation} from "@pg-design/elevation";
import {borderRadius} from "@pg-design/helpers-css";
import {getThemeBreakpoint} from "@pg-design/styles-strings";

export const OfferBoxBase = styled.div`
    ${elevation(2)}
    width: 100%;
    min-width: 288px;
    max-width: 375px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    transition: box-shadow 0.2s ease-in-out;
    ${borderRadius(2, 2, 2, 2)}

    &:hover {
        ${elevation(3)}
    }
`;

export const offerBoxImgWrapperStyle = css`
    height: 20.4rem;

    @media (min-width: ${getThemeBreakpoint().md}) {
        height: 24.6rem;
    }
`;

export const offerBoxImgStyle: CSSProperties = {
    objectFit: "cover",
    height: "100%",
    width: "100%"
};
