import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

enum OfferBoxGTMEventType {
    INVEST_TILE = "invest_tile",
    INVEST_TILE_SELECT = "invest_tile_select"
}

const investTileSwipe = (offerId: number, tileIndex: number, swipesNumber: number) => {
    hitGoogleTagManager({
        event: OfferBoxGTMEventType.INVEST_TILE,
        invest_id: `${offerId}`,
        invest_number: tileIndex + 1, // index of the investment in the list (starting from 1)
        swipes_number: swipesNumber // number of swipes e.g. "3" - 2 swipes to the right and 1 swipe to the left
    });
};

const investTileSelect = (offerId: number, tileIndex: number, swipesNumber: number | null, slideIndex: number | null) => {
    hitGoogleTagManager({
        event: OfferBoxGTMEventType.INVEST_TILE_SELECT,
        invest_id: `${offerId}`,
        invest_number: tileIndex + 1, // index of the investment in the list (starting from 1)
        swipes_number: swipesNumber || "nie_dotyczy", // number of swipes e.g. "3" - 2 swipes to the right and 1 swipe to the left
        selected_tile: typeof slideIndex === "number" ? slideIndex + 1 : "nie_dotyczy" // index of slide (starting from 1) in the moment of selecting the investment
    });
};

export const offerBoxTracking = {
    gtm: {
        investTileSwipe,
        investTileSelect
    }
};
import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

enum OfferBoxGTMEventType {
    INVEST_TILE = "invest_tile",
    INVEST_TILE_SELECT = "invest_tile_select"
}

const investTileSwipe = (offerId: number, tileIndex: number, swipesNumber: number) => {
    hitGoogleTagManager({
        event: OfferBoxGTMEventType.INVEST_TILE,
        invest_id: `${offerId}`,
        invest_number: tileIndex + 1, // index of the investment in the list (starting from 1)
        swipes_number: swipesNumber // number of swipes e.g. "3" - 2 swipes to the right and 1 swipe to the left
    });
};

const investTileSelect = (offerId: number, tileIndex: number, swipesNumber: number | null, slideIndex: number | null) => {
    hitGoogleTagManager({
        event: OfferBoxGTMEventType.INVEST_TILE_SELECT,
        invest_id: `${offerId}`,
        invest_number: tileIndex + 1, // index of the investment in the list (starting from 1)
        swipes_number: swipesNumber || "nie_dotyczy", // number of swipes e.g. "3" - 2 swipes to the right and 1 swipe to the left
        selected_tile: typeof slideIndex === "number" ? slideIndex + 1 : "nie_dotyczy" // index of slide (starting from 1) in the moment of selecting the investment
    });
};

export const offerBoxTracking = {
    gtm: {
        investTileSwipe,
        investTileSelect
    }
};
