import {IOfferDetail} from "../types/IOfferDetail";

interface IOfferConfiguration {
    configuration: Pick<IOfferDetail["configuration"], "pre_sale">;
}

export const isOfferPreSale = (offer: IOfferConfiguration) => "pre_sale" in offer.configuration && !!offer.configuration.pre_sale;
import {IOfferDetail} from "../types/IOfferDetail";

interface IOfferConfiguration {
    configuration: Pick<IOfferDetail["configuration"], "pre_sale">;
}

export const isOfferPreSale = (offer: IOfferConfiguration) => "pre_sale" in offer.configuration && !!offer.configuration.pre_sale;
