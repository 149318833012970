import {useMemo} from "react";

import {dateTimeFormat, formatFutureDate, pluralize, priceFormat, priceM2Format, replaceSpaceWithNonBreakingSpace} from "@pg-mono/string-utils";

import {Country} from "../../region/types/Country";
import {getCurrency} from "../../utils/get_currency";
import {ILatestQuery} from "../../utils/latest_query";
import {IOfferBoxOffer} from "../types/IOfferBoxOffer";
import {OfferPriceType} from "../types/OfferPriceType";

interface IProps {
    offer: IOfferBoxOffer;
    showFullPrice?: boolean;
    latestQuery?: ILatestQuery & Record<string, string | string[] | null>;
}

export const useOfferDetails = (props: IProps) => {
    const {offer, showFullPrice, latestQuery} = props;
    const currency = getCurrency({currency: offer.currency});
    const priceType = offer.region.country === Country.SPAIN && offer.price_type === OfferPriceType.NETTO ? " netto" : "";

    return useMemo(() => {
        const streetName = offer.street_name ? `${offer.street_name}${offer.street_number ? ` ${offer.street_number}` : ""}` : "";

        const roomFilterLower = parseInt(latestQuery?.rooms_0 || "") || 0;
        const roomFilterUpper = parseInt(latestQuery?.rooms_1 || "") || 0;
        const roomLower = offer.stats?.ranges_rooms_min ?? 0;
        const roomUpper = offer.stats?.ranges_rooms_max ?? 0;
        const roomLowerToDisplay = roomFilterLower ? Math.max(roomFilterLower, roomLower) : roomLower;
        let roomUpperToDisplay = roomFilterUpper ? Math.min(roomFilterUpper, roomUpper) : roomUpper;
        if (roomLowerToDisplay > roomUpperToDisplay) {
            roomUpperToDisplay = roomLowerToDisplay;
        }
        const roomText = `${roomLowerToDisplay === roomUpperToDisplay ? roomUpperToDisplay : `${roomLowerToDisplay}-${roomUpperToDisplay}`} ${roomsPluralize(roomUpperToDisplay)}`;

        const areaFilterLower = parseInt(latestQuery?.area_0 || "") || -1;
        const areaFilterUpper = parseInt(latestQuery?.area_1 || "") || 10000;
        const areaLower = offer.stats?.ranges_area_min ?? 0;
        const areaUpper = offer.stats?.ranges_area_max ?? 0;
        const areaLowerToDisplay = Math.max(areaFilterLower, areaLower);
        let areaUpperToDisplay = Math.min(areaFilterUpper, areaUpper);
        if (areaLowerToDisplay > areaUpperToDisplay) {
            areaUpperToDisplay = areaLowerToDisplay;
        }
        const areaText = `${areaLowerToDisplay === areaUpperToDisplay ? areaUpperToDisplay : `${areaLowerToDisplay}-${areaUpperToDisplay}`} m2`;

        return {
            priceToShow:
                (((showFullPrice && offer.stats?.ranges_price_min) || offer.stats?.ranges_price_m2_min) ?? 0) > 0
                    ? (showFullPrice && offer.stats?.ranges_price_min
                          ? priceFormat(offer.stats?.ranges_price_min ?? 0, {unit: currency})
                          : priceM2Format(offer.stats?.ranges_price_m2_min ?? 0, {unit: currency})) + priceType
                    : null,
            addressText: `${offer.region.short_name ?? ""}${offer.street_name && offer.region.short_name ? ", " : ""}${replaceSpaceWithNonBreakingSpace(streetName)}`,
            planText: `${roomText}, Metraż ${areaText}`,
            constructionEndDate: formatFutureDate(offer.construction_date_range.upper, dateTimeFormat.quarterLong)
        };
    }, [offer, showFullPrice, latestQuery?.rooms_0, latestQuery?.rooms_1, latestQuery?.area_0, latestQuery?.area_1]);
};

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);
import {useMemo} from "react";

import {dateTimeFormat, formatFutureDate, pluralize, priceFormat, priceM2Format, replaceSpaceWithNonBreakingSpace} from "@pg-mono/string-utils";

import {Country} from "../../region/types/Country";
import {getCurrency} from "../../utils/get_currency";
import {ILatestQuery} from "../../utils/latest_query";
import {IOfferBoxOffer} from "../types/IOfferBoxOffer";
import {OfferPriceType} from "../types/OfferPriceType";

interface IProps {
    offer: IOfferBoxOffer;
    showFullPrice?: boolean;
    latestQuery?: ILatestQuery & Record<string, string | string[] | null>;
}

export const useOfferDetails = (props: IProps) => {
    const {offer, showFullPrice, latestQuery} = props;
    const currency = getCurrency({currency: offer.currency});
    const priceType = offer.region.country === Country.SPAIN && offer.price_type === OfferPriceType.NETTO ? " netto" : "";

    return useMemo(() => {
        const streetName = offer.street_name ? `${offer.street_name}${offer.street_number ? ` ${offer.street_number}` : ""}` : "";

        const roomFilterLower = parseInt(latestQuery?.rooms_0 || "") || 0;
        const roomFilterUpper = parseInt(latestQuery?.rooms_1 || "") || 0;
        const roomLower = offer.stats?.ranges_rooms_min ?? 0;
        const roomUpper = offer.stats?.ranges_rooms_max ?? 0;
        const roomLowerToDisplay = roomFilterLower ? Math.max(roomFilterLower, roomLower) : roomLower;
        let roomUpperToDisplay = roomFilterUpper ? Math.min(roomFilterUpper, roomUpper) : roomUpper;
        if (roomLowerToDisplay > roomUpperToDisplay) {
            roomUpperToDisplay = roomLowerToDisplay;
        }
        const roomText = `${roomLowerToDisplay === roomUpperToDisplay ? roomUpperToDisplay : `${roomLowerToDisplay}-${roomUpperToDisplay}`} ${roomsPluralize(roomUpperToDisplay)}`;

        const areaFilterLower = parseInt(latestQuery?.area_0 || "") || -1;
        const areaFilterUpper = parseInt(latestQuery?.area_1 || "") || 10000;
        const areaLower = offer.stats?.ranges_area_min ?? 0;
        const areaUpper = offer.stats?.ranges_area_max ?? 0;
        const areaLowerToDisplay = Math.max(areaFilterLower, areaLower);
        let areaUpperToDisplay = Math.min(areaFilterUpper, areaUpper);
        if (areaLowerToDisplay > areaUpperToDisplay) {
            areaUpperToDisplay = areaLowerToDisplay;
        }
        const areaText = `${areaLowerToDisplay === areaUpperToDisplay ? areaUpperToDisplay : `${areaLowerToDisplay}-${areaUpperToDisplay}`} m2`;

        return {
            priceToShow:
                (((showFullPrice && offer.stats?.ranges_price_min) || offer.stats?.ranges_price_m2_min) ?? 0) > 0
                    ? (showFullPrice && offer.stats?.ranges_price_min
                          ? priceFormat(offer.stats?.ranges_price_min ?? 0, {unit: currency})
                          : priceM2Format(offer.stats?.ranges_price_m2_min ?? 0, {unit: currency})) + priceType
                    : null,
            addressText: `${offer.region.short_name ?? ""}${offer.street_name && offer.region.short_name ? ", " : ""}${replaceSpaceWithNonBreakingSpace(streetName)}`,
            planText: `${roomText}, Metraż ${areaText}`,
            constructionEndDate: formatFutureDate(offer.construction_date_range.upper, dateTimeFormat.quarterLong)
        };
    }, [offer, showFullPrice, latestQuery?.rooms_0, latestQuery?.rooms_1, latestQuery?.area_0, latestQuery?.area_1]);
};

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);
