import {TouchEvent, useState} from "react";

import {getTranslateX} from "../utils/get_translate_x";

interface IProps {
    currentSlideIndex: number;
    totalSlides: number;
    slideWidth: number;
    maxAllowedOutsideSwipe?: number;
    disableMobileSwipe?: boolean;
    prevSlide: () => void;
    nextSlide: () => void;
    onSlideClick?: () => void;
}

export const useSimpleSliderSwipe = (props: IProps) => {
    const [isSwiping, setIsSwiping] = useState(false);
    const [startX, setStartX] = useState<number | null>(null);
    const [translateX, setTranslateX] = useState(0);

    const getCurrentSlideTranslateX = () => {
        return getTranslateX(props.currentSlideIndex, props.slideWidth);
    };

    const handleTouchStart = (event: TouchEvent) => {
        event.stopPropagation();
        setIsSwiping(true);
        setStartX(event.touches[0].clientX);
        setTranslateX(getCurrentSlideTranslateX());
    };

    const getTranslateXWithDiffX = (diffX: number) => {
        if (typeof props.maxAllowedOutsideSwipe === "number" && props.currentSlideIndex === 0) {
            // first slide
            return getCurrentSlideTranslateX() + Math.min(diffX, props.maxAllowedOutsideSwipe);
        } else if (typeof props.maxAllowedOutsideSwipe === "number" && props.currentSlideIndex === props.totalSlides - 1) {
            // last slide
            return getCurrentSlideTranslateX() + Math.max(diffX, -props.maxAllowedOutsideSwipe);
        }

        return getCurrentSlideTranslateX() + diffX;
    };

    const handleTouchMove = (event: TouchEvent) => {
        event.stopPropagation();
        if (!isSwiping || startX === null) {
            return;
        }

        const currentX = event.touches[0].clientX;
        const diffX = currentX - startX;

        // Prevent scrolling when swiping
        if (Math.abs(diffX) > 5 && event.cancelable) {
            event.preventDefault();
        }

        setTranslateX(getTranslateXWithDiffX(diffX));
    };

    const handleTouchEnd = () => {
        if (!isSwiping || startX === null) {
            return;
        }

        const threshold = 50; // Swipe at least 50px to change slide
        const diffX = translateX - getCurrentSlideTranslateX();

        if (diffX > threshold) {
            props.prevSlide();
        } else if (diffX < -threshold && diffX !== 0) {
            props.nextSlide();
        } else if (diffX === 0) {
            props.onSlideClick?.();
        } else {
            // Stay on the same slide
            setTranslateX(getCurrentSlideTranslateX());
        }

        setIsSwiping(false);
        setStartX(null);
    };

    return props.disableMobileSwipe
        ? {
              handleTouchStart: undefined,
              handleTouchMove: undefined,
              handleTouchEnd: undefined,
              isSwiping: false,
              startX: null,
              translateX: undefined
          }
        : {
              handleTouchStart,
              handleTouchMove,
              handleTouchEnd,
              isSwiping,
              startX,
              translateX
          };
};
import {TouchEvent, useState} from "react";

import {getTranslateX} from "../utils/get_translate_x";

interface IProps {
    currentSlideIndex: number;
    totalSlides: number;
    slideWidth: number;
    maxAllowedOutsideSwipe?: number;
    disableMobileSwipe?: boolean;
    prevSlide: () => void;
    nextSlide: () => void;
    onSlideClick?: () => void;
}

export const useSimpleSliderSwipe = (props: IProps) => {
    const [isSwiping, setIsSwiping] = useState(false);
    const [startX, setStartX] = useState<number | null>(null);
    const [translateX, setTranslateX] = useState(0);

    const getCurrentSlideTranslateX = () => {
        return getTranslateX(props.currentSlideIndex, props.slideWidth);
    };

    const handleTouchStart = (event: TouchEvent) => {
        event.stopPropagation();
        setIsSwiping(true);
        setStartX(event.touches[0].clientX);
        setTranslateX(getCurrentSlideTranslateX());
    };

    const getTranslateXWithDiffX = (diffX: number) => {
        if (typeof props.maxAllowedOutsideSwipe === "number" && props.currentSlideIndex === 0) {
            // first slide
            return getCurrentSlideTranslateX() + Math.min(diffX, props.maxAllowedOutsideSwipe);
        } else if (typeof props.maxAllowedOutsideSwipe === "number" && props.currentSlideIndex === props.totalSlides - 1) {
            // last slide
            return getCurrentSlideTranslateX() + Math.max(diffX, -props.maxAllowedOutsideSwipe);
        }

        return getCurrentSlideTranslateX() + diffX;
    };

    const handleTouchMove = (event: TouchEvent) => {
        event.stopPropagation();
        if (!isSwiping || startX === null) {
            return;
        }

        const currentX = event.touches[0].clientX;
        const diffX = currentX - startX;

        // Prevent scrolling when swiping
        if (Math.abs(diffX) > 5 && event.cancelable) {
            event.preventDefault();
        }

        setTranslateX(getTranslateXWithDiffX(diffX));
    };

    const handleTouchEnd = () => {
        if (!isSwiping || startX === null) {
            return;
        }

        const threshold = 50; // Swipe at least 50px to change slide
        const diffX = translateX - getCurrentSlideTranslateX();

        if (diffX > threshold) {
            props.prevSlide();
        } else if (diffX < -threshold && diffX !== 0) {
            props.nextSlide();
        } else if (diffX === 0) {
            props.onSlideClick?.();
        } else {
            // Stay on the same slide
            setTranslateX(getCurrentSlideTranslateX());
        }

        setIsSwiping(false);
        setStartX(null);
    };

    return props.disableMobileSwipe
        ? {
              handleTouchStart: undefined,
              handleTouchMove: undefined,
              handleTouchEnd: undefined,
              isSwiping: false,
              startX: null,
              translateX: undefined
          }
        : {
              handleTouchStart,
              handleTouchMove,
              handleTouchEnd,
              isSwiping,
              startX,
              translateX
          };
};
