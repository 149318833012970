import React from "react";
import {css} from "@emotion/react";

import {backgroundColor, borderRadius, calculateRemSize, flexAbsoluteCenter} from "@pg-design/helpers-css-strings";
import {ChevronLeftIcon} from "@pg-design/icons";

export const SimpleSliderArrowLeft = () => {
    return (
        <div css={arrowStyle}>
            <ChevronLeftIcon size="1.2" />
        </div>
    );
};

const arrowStyle = css`
    ${flexAbsoluteCenter};
    ${backgroundColor("#fff")};
    width: ${calculateRemSize(4)};
    height: ${calculateRemSize(4)};
    ${borderRadius(2)};
    user-select: none;
`;
import React from "react";
import {css} from "@emotion/react";

import {backgroundColor, borderRadius, calculateRemSize, flexAbsoluteCenter} from "@pg-design/helpers-css-strings";
import {ChevronLeftIcon} from "@pg-design/icons";

export const SimpleSliderArrowLeft = () => {
    return (
        <div css={arrowStyle}>
            <ChevronLeftIcon size="1.2" />
        </div>
    );
};

const arrowStyle = css`
    ${flexAbsoluteCenter};
    ${backgroundColor("#fff")};
    width: ${calculateRemSize(4)};
    height: ${calculateRemSize(4)};
    ${borderRadius(2)};
    user-select: none;
`;
