import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const EyeIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M 9.78 4.908354 C 9.73 4.848354 9.23 4.218354 8.41 3.568354 C 7.92 3.178354 7.41 2.858354 6.91 2.638354 C 6.38 2.398354 5.87 2.268353 5.35 2.228354 C 5.24 2.218354 5.12 2.208354 5 2.208354 C 4.88 2.208354 4.77 2.208354 4.66 2.228354 C 4.14 2.268353 3.63 2.408354 3.1 2.638354 C 2.6 2.858354 2.1 3.178354 1.6 3.568354 C 0.75 4.248354 0.24 4.908354 0.23 4.908354 L -0 5.198354 L 0.23 5.488354 C 0.23 5.488354 0.75 6.158354 1.6 6.838333 C 2.09 7.228333 2.6 7.538375 3.1 7.768375 C 3.74 8.058375 4.38 8.208333 5.01 8.208333 C 5.63 8.208333 6.27 8.058375 6.92 7.768375 C 7.42 7.538375 7.92 7.228333 8.42 6.838333 C 9.27 6.158354 9.78 5.498354 9.79 5.498354 L 10.02 5.208354 L 9.79 4.918355 L 9.78 4.908354 Z M 5.3 7.698333 C 5.1 7.718333 4.91 7.718333 4.71 7.698333 C 3.47 7.548333 2.5 6.488333 2.5 5.198354 C 2.5 3.908354 3.47 2.848354 4.71 2.698354 C 4.9 2.678354 5.1 2.678354 5.29 2.698354 C 6.53 2.848354 7.5 3.908354 7.5 5.198354 C 7.5 6.488333 6.53 7.548333 5.29 7.698333 L 5.3 7.698333 Z M 0.62 5.198354 C 0.62 5.198354 1.11 4.568354 1.9 3.938354 C 2.12 3.758354 2.35 3.598354 2.57 3.458354 C 2.22 3.948354 2.02 4.548354 2.02 5.188354 C 2.02 5.828354 2.23 6.438334 2.58 6.928333 C 2.36 6.788333 2.13 6.628333 1.91 6.448333 C 1.14 5.838354 0.67 5.248354 0.63 5.188354 L 0.62 5.198354 Z M 8.110001 6.458333 C 7.89 6.638333 7.66 6.798333 7.440001 6.938334 C 7.789999 6.448333 8 5.848354 8 5.198354 C 8 4.548354 7.789999 3.958354 7.45 3.468354 C 7.670001 3.608354 7.9 3.768354 8.120001 3.948354 C 8.910001 4.578354 9.389999 5.188354 9.4 5.208354 C 9.389999 5.228354 8.910001 5.838354 8.120001 6.468333 L 8.110001 6.458333 Z"
            />
            <path
                stroke="none"
                d="M 5.010292 4.168296 C 4.440292 4.168296 3.980304 4.628296 3.980304 5.198296 C 3.980304 5.768296 4.440292 6.228296 5.010292 6.228296 C 5.580292 6.228296 6.040292 5.768296 6.040292 5.198296 C 6.040292 4.628296 5.580292 4.168296 5.010292 4.168296 L 5.010292 4.168296 Z"
            />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const EyeIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path
                stroke="none"
                d="M 9.78 4.908354 C 9.73 4.848354 9.23 4.218354 8.41 3.568354 C 7.92 3.178354 7.41 2.858354 6.91 2.638354 C 6.38 2.398354 5.87 2.268353 5.35 2.228354 C 5.24 2.218354 5.12 2.208354 5 2.208354 C 4.88 2.208354 4.77 2.208354 4.66 2.228354 C 4.14 2.268353 3.63 2.408354 3.1 2.638354 C 2.6 2.858354 2.1 3.178354 1.6 3.568354 C 0.75 4.248354 0.24 4.908354 0.23 4.908354 L -0 5.198354 L 0.23 5.488354 C 0.23 5.488354 0.75 6.158354 1.6 6.838333 C 2.09 7.228333 2.6 7.538375 3.1 7.768375 C 3.74 8.058375 4.38 8.208333 5.01 8.208333 C 5.63 8.208333 6.27 8.058375 6.92 7.768375 C 7.42 7.538375 7.92 7.228333 8.42 6.838333 C 9.27 6.158354 9.78 5.498354 9.79 5.498354 L 10.02 5.208354 L 9.79 4.918355 L 9.78 4.908354 Z M 5.3 7.698333 C 5.1 7.718333 4.91 7.718333 4.71 7.698333 C 3.47 7.548333 2.5 6.488333 2.5 5.198354 C 2.5 3.908354 3.47 2.848354 4.71 2.698354 C 4.9 2.678354 5.1 2.678354 5.29 2.698354 C 6.53 2.848354 7.5 3.908354 7.5 5.198354 C 7.5 6.488333 6.53 7.548333 5.29 7.698333 L 5.3 7.698333 Z M 0.62 5.198354 C 0.62 5.198354 1.11 4.568354 1.9 3.938354 C 2.12 3.758354 2.35 3.598354 2.57 3.458354 C 2.22 3.948354 2.02 4.548354 2.02 5.188354 C 2.02 5.828354 2.23 6.438334 2.58 6.928333 C 2.36 6.788333 2.13 6.628333 1.91 6.448333 C 1.14 5.838354 0.67 5.248354 0.63 5.188354 L 0.62 5.198354 Z M 8.110001 6.458333 C 7.89 6.638333 7.66 6.798333 7.440001 6.938334 C 7.789999 6.448333 8 5.848354 8 5.198354 C 8 4.548354 7.789999 3.958354 7.45 3.468354 C 7.670001 3.608354 7.9 3.768354 8.120001 3.948354 C 8.910001 4.578354 9.389999 5.188354 9.4 5.208354 C 9.389999 5.228354 8.910001 5.838354 8.120001 6.468333 L 8.110001 6.458333 Z"
            />
            <path
                stroke="none"
                d="M 5.010292 4.168296 C 4.440292 4.168296 3.980304 4.628296 3.980304 5.198296 C 3.980304 5.768296 4.440292 6.228296 5.010292 6.228296 C 5.580292 6.228296 6.040292 5.768296 6.040292 5.198296 C 6.040292 4.628296 5.580292 4.168296 5.010292 4.168296 L 5.010292 4.168296 Z"
            />
        </SvgIcon>
    );
};
