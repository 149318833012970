import React from "react";
import {css} from "@emotion/react";

import {pointer} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {rpAppLink} from "@pg-mono/rp-routes";

import {useResponsiveLinkTarget} from "../../../hooks/use_responsive_link_target";

interface IProps {
    unlinkVendor?: boolean;
    vendorId?: number;
    vendorSlug?: string;
    vendorName: string;
    vendorLogo?: string | null;
    width?: string;
    height?: string;
}

export const OfferBoxVendorImage = (props: IProps) => {
    const target = useResponsiveLinkTarget();
    const vendorLink = props.vendorSlug && props.vendorId ? rpAppLink.vendor.detail.base({vendorSlug: props.vendorSlug, vendorId: props.vendorId}) : null;
    const width = props.width || "80px";
    const height = props.height || "60px";

    if (!props.vendorLogo) {
        return <div style={{height, width}} css={wrapperStyle} />;
    }

    const vendorImage = <Image alt={props.vendorName} src={props.vendorLogo} width={width} height={height} />;

    if (!vendorLink || props.unlinkVendor) {
        return <div css={wrapperStyle}>{vendorImage}</div>;
    }

    return (
        <a css={[pointer, wrapperStyle]} href={vendorLink} target={target}>
            {vendorImage}
        </a>
    );
};

const wrapperStyle = css`
    flex: 0 0 auto;
    display: inline-flex;
`;
import React from "react";
import {css} from "@emotion/react";

import {pointer} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {rpAppLink} from "@pg-mono/rp-routes";

import {useResponsiveLinkTarget} from "../../../hooks/use_responsive_link_target";

interface IProps {
    unlinkVendor?: boolean;
    vendorId?: number;
    vendorSlug?: string;
    vendorName: string;
    vendorLogo?: string | null;
    width?: string;
    height?: string;
}

export const OfferBoxVendorImage = (props: IProps) => {
    const target = useResponsiveLinkTarget();
    const vendorLink = props.vendorSlug && props.vendorId ? rpAppLink.vendor.detail.base({vendorSlug: props.vendorSlug, vendorId: props.vendorId}) : null;
    const width = props.width || "80px";
    const height = props.height || "60px";

    if (!props.vendorLogo) {
        return <div style={{height, width}} css={wrapperStyle} />;
    }

    const vendorImage = <Image alt={props.vendorName} src={props.vendorLogo} width={width} height={height} />;

    if (!vendorLink || props.unlinkVendor) {
        return <div css={wrapperStyle}>{vendorImage}</div>;
    }

    return (
        <a css={[pointer, wrapperStyle]} href={vendorLink} target={target}>
            {vendorImage}
        </a>
    );
};

const wrapperStyle = css`
    flex: 0 0 auto;
    display: inline-flex;
`;
