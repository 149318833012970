import {css} from "@emotion/react";
import styled from "@emotion/styled";

// We want to keep the layout of the box offer same at all times, despite the fact it may be missing some data:
export const NoTagPlug = styled.div<{height: string; width?: string}>`
    ${({height, width}) => css`
        height: ${height};
        ${width && `width: ${width}`}
    `}
`;
import {css} from "@emotion/react";
import styled from "@emotion/styled";

// We want to keep the layout of the box offer same at all times, despite the fact it may be missing some data:
export const NoTagPlug = styled.div<{height: string; width?: string}>`
    ${({height, width}) => css`
        height: ${height};
        ${width && `width: ${width}`}
    `}
`;
