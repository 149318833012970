import React, {useEffect, useRef, useState} from "react";

import {ISimpleSliderProps} from "../types/ISimpleSliderProps";

export const useSimpleSlider = (props: ISimpleSliderProps) => {
    const {children, loop = false} = props;

    const sliderRef = useRef<HTMLDivElement>(null);
    const sliderTrackRef = useRef<HTMLDivElement>(null);

    const [currentIndex, setCurrentIndex] = useState(0);
    const totalSlides = React.Children.count(children);
    const [slideWidth, setSlideWidth] = useState(0);

    useEffect(() => {
        const refreshSlideWidth = () => {
            requestAnimationFrame(() => {
                setSlideWidth(sliderRef.current?.clientWidth || 0);
            });
        };

        const resizeObserver = new ResizeObserver(refreshSlideWidth);
        if (sliderRef.current) {
            resizeObserver.observe(sliderRef.current);
            refreshSlideWidth();
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [sliderRef.current]);

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => {
            if (loop) {
                return prevIndex === 0 ? totalSlides - 1 : prevIndex - 1;
            }

            return Math.max(prevIndex - 1, 0);
        });
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => {
            if (loop) {
                return (prevIndex + 1) % totalSlides;
            }

            return Math.min(prevIndex + 1, totalSlides - 1);
        });
    };

    return {
        sliderRef,
        sliderTrackRef,
        slideWidth,
        currentSlideIndex: currentIndex,
        totalSlides,
        prevSlide,
        nextSlide
    };
};
import React, {useEffect, useRef, useState} from "react";

import {ISimpleSliderProps} from "../types/ISimpleSliderProps";

export const useSimpleSlider = (props: ISimpleSliderProps) => {
    const {children, loop = false} = props;

    const sliderRef = useRef<HTMLDivElement>(null);
    const sliderTrackRef = useRef<HTMLDivElement>(null);

    const [currentIndex, setCurrentIndex] = useState(0);
    const totalSlides = React.Children.count(children);
    const [slideWidth, setSlideWidth] = useState(0);

    useEffect(() => {
        const refreshSlideWidth = () => {
            requestAnimationFrame(() => {
                setSlideWidth(sliderRef.current?.clientWidth || 0);
            });
        };

        const resizeObserver = new ResizeObserver(refreshSlideWidth);
        if (sliderRef.current) {
            resizeObserver.observe(sliderRef.current);
            refreshSlideWidth();
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [sliderRef.current]);

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => {
            if (loop) {
                return prevIndex === 0 ? totalSlides - 1 : prevIndex - 1;
            }

            return Math.max(prevIndex - 1, 0);
        });
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => {
            if (loop) {
                return (prevIndex + 1) % totalSlides;
            }

            return Math.min(prevIndex + 1, totalSlides - 1);
        });
    };

    return {
        sliderRef,
        sliderTrackRef,
        slideWidth,
        currentSlideIndex: currentIndex,
        totalSlides,
        prevSlide,
        nextSlide
    };
};
