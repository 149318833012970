import {useIsMobile} from "@pg-mono/hooks";

export const useResponsiveOpenLink = (link: string) => {
    const isMobile = useIsMobile();
    return () => (isMobile ? (window.location.href = link) : window.open(link, "_blank"));
};
import {useIsMobile} from "@pg-mono/hooks";

export const useResponsiveOpenLink = (link: string) => {
    const isMobile = useIsMobile();
    return () => (isMobile ? (window.location.href = link) : window.open(link, "_blank"));
};
