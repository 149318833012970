import React, {CSSProperties, forwardRef} from "react";

import {useIsMounted} from "@pg-mono/hooks";

import {ISimpleSliderProps} from "../types/ISimpleSliderProps";
import {getTranslateX} from "../utils/get_translate_x";
import {SimpleSliderSlide} from "./SimpleSliderSlide";

type IProps = ISimpleSliderProps & {
    totalSlides: number;
    currentIndex: number;
    isSwiping: boolean;
    slideWidth: number;
    translateX?: number;
};

export const SimpleSliderTrack = forwardRef<HTMLDivElement, IProps>((props, ref) => {
    const {
        isSwiping,
        translateX,
        currentIndex,
        totalSlides,
        children,
        transitionDuration = 300,
        transitionEasing = "ease",
        loop = false,
        lazyLoadOffset = 1
    } = props;

    const isMounted = useIsMounted();

    const slideWidth = isMounted ? props.slideWidth : 0;
    const trackWidth = isMounted ? `${totalSlides * 100}%` : "100%";
    const trackStyle: CSSProperties = {
        display: "flex",
        transition: isSwiping ? "none" : `transform ${transitionDuration}ms ${transitionEasing}`,
        transform: `translateX(${isSwiping && typeof translateX === "number" ? translateX : getTranslateX(currentIndex, slideWidth)}px)`,
        width: trackWidth,
        touchAction: "pan-y"
    };

    return (
        <div ref={ref} style={trackStyle}>
            {React.Children.map(children, (child, index) => {
                if (loop) {
                    const adjustedIndex = currentIndex < lazyLoadOffset ? totalSlides + (currentIndex - lazyLoadOffset) : currentIndex - lazyLoadOffset;
                    const loopedIndex = (index + totalSlides - adjustedIndex) % totalSlides;
                    if (loopedIndex <= lazyLoadOffset * 2) {
                        return (
                            <SimpleSliderSlide key={index} width={slideWidth}>
                                {child}
                            </SimpleSliderSlide>
                        );
                    }
                }

                const shouldRender = index >= currentIndex - lazyLoadOffset && index <= currentIndex + lazyLoadOffset;
                return shouldRender ? (
                    <SimpleSliderSlide key={index} width={slideWidth}>
                        {child}
                    </SimpleSliderSlide>
                ) : (
                    // Render an empty placeholder div to maintain layout
                    <SimpleSliderSlide key={index} width={slideWidth} />
                );
            })}
        </div>
    );
});
import React, {CSSProperties, forwardRef} from "react";

import {useIsMounted} from "@pg-mono/hooks";

import {ISimpleSliderProps} from "../types/ISimpleSliderProps";
import {getTranslateX} from "../utils/get_translate_x";
import {SimpleSliderSlide} from "./SimpleSliderSlide";

type IProps = ISimpleSliderProps & {
    totalSlides: number;
    currentIndex: number;
    isSwiping: boolean;
    slideWidth: number;
    translateX?: number;
};

export const SimpleSliderTrack = forwardRef<HTMLDivElement, IProps>((props, ref) => {
    const {
        isSwiping,
        translateX,
        currentIndex,
        totalSlides,
        children,
        transitionDuration = 300,
        transitionEasing = "ease",
        loop = false,
        lazyLoadOffset = 1
    } = props;

    const isMounted = useIsMounted();

    const slideWidth = isMounted ? props.slideWidth : 0;
    const trackWidth = isMounted ? `${totalSlides * 100}%` : "100%";
    const trackStyle: CSSProperties = {
        display: "flex",
        transition: isSwiping ? "none" : `transform ${transitionDuration}ms ${transitionEasing}`,
        transform: `translateX(${isSwiping && typeof translateX === "number" ? translateX : getTranslateX(currentIndex, slideWidth)}px)`,
        width: trackWidth,
        touchAction: "pan-y"
    };

    return (
        <div ref={ref} style={trackStyle}>
            {React.Children.map(children, (child, index) => {
                if (loop) {
                    const adjustedIndex = currentIndex < lazyLoadOffset ? totalSlides + (currentIndex - lazyLoadOffset) : currentIndex - lazyLoadOffset;
                    const loopedIndex = (index + totalSlides - adjustedIndex) % totalSlides;
                    if (loopedIndex <= lazyLoadOffset * 2) {
                        return (
                            <SimpleSliderSlide key={index} width={slideWidth}>
                                {child}
                            </SimpleSliderSlide>
                        );
                    }
                }

                const shouldRender = index >= currentIndex - lazyLoadOffset && index <= currentIndex + lazyLoadOffset;
                return shouldRender ? (
                    <SimpleSliderSlide key={index} width={slideWidth}>
                        {child}
                    </SimpleSliderSlide>
                ) : (
                    // Render an empty placeholder div to maintain layout
                    <SimpleSliderSlide key={index} width={slideWidth} />
                );
            })}
        </div>
    );
});
