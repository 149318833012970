import {useRef} from "react";

import {offerBoxTracking} from "../tracking/offer_box_gtm_events";

interface IProps {
    offerId: number;
    offerBoxIndex: number;
}

export const useOfferBoxAnalytics = (props: IProps) => {
    const swipeData = useRef({
        left: 0,
        right: 0,
        currentSlide: 0
    });

    const onPrevClick = (slideIndex: number) => {
        swipeData.current.left++;
        swipeData.current.currentSlide = slideIndex;
        reportSwipe();
    };

    const onNextClick = (slideIndex: number) => {
        swipeData.current.right++;
        swipeData.current.currentSlide = slideIndex;
        reportSwipe();
    };

    const reportSwipe = () => {
        offerBoxTracking.gtm.investTileSwipe(props.offerId, props.offerBoxIndex, swipeData.current.right + swipeData.current.left);
    };

    const reportOfferSelect = () => {
        offerBoxTracking.gtm.investTileSelect(
            props.offerId,
            props.offerBoxIndex,
            swipeData.current.right + swipeData.current.left,
            swipeData.current.currentSlide
        );
    };

    return {
        reportOfferSelect,
        onPrevClick,
        onNextClick
    };
};
import {useRef} from "react";

import {offerBoxTracking} from "../tracking/offer_box_gtm_events";

interface IProps {
    offerId: number;
    offerBoxIndex: number;
}

export const useOfferBoxAnalytics = (props: IProps) => {
    const swipeData = useRef({
        left: 0,
        right: 0,
        currentSlide: 0
    });

    const onPrevClick = (slideIndex: number) => {
        swipeData.current.left++;
        swipeData.current.currentSlide = slideIndex;
        reportSwipe();
    };

    const onNextClick = (slideIndex: number) => {
        swipeData.current.right++;
        swipeData.current.currentSlide = slideIndex;
        reportSwipe();
    };

    const reportSwipe = () => {
        offerBoxTracking.gtm.investTileSwipe(props.offerId, props.offerBoxIndex, swipeData.current.right + swipeData.current.left);
    };

    const reportOfferSelect = () => {
        offerBoxTracking.gtm.investTileSelect(
            props.offerId,
            props.offerBoxIndex,
            swipeData.current.right + swipeData.current.left,
            swipeData.current.currentSlide
        );
    };

    return {
        reportOfferSelect,
        onPrevClick,
        onNextClick
    };
};
