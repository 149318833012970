import React, {useMemo} from "react";
import {css} from "@emotion/react";

import {Badge} from "@pg-design/badge-module";
import {borderRadius, calculateRemSize} from "@pg-design/helpers-css-strings";
import {InfoIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";
import {useIsMounted} from "@pg-mono/hooks";
import {SimpleSlider} from "@pg-mono/simple-slider";
import {useUserDevice} from "@pg-mono/user-device";

import {offerBoxImgStyle, offerBoxImgWrapperStyle} from "../../../atoms/OfferBoxBase";
import {useResponsiveOpenLink} from "../../../hooks/use_responsive_open_link";
import {SubscribeButton} from "../../../real_estate/components/SubscribeButton";
import {IOfferBoxOffer} from "../../types/IOfferBoxOffer";
import {hasOfferActivePromotions} from "../../utils/has_offer_active_promotions";
import {isOfferPreSale} from "../../utils/is_offer_pre_sale";
import {OfferBoxTopSectionMap} from "./OfferBoxTopSectionMap";

const presaleOfferDefaultImg = require("../../images/presale_offer_default_img_375x212.jpg");

interface IProps {
    offer: IOfferBoxOffer;
    offerLink: string;
    index?: number;
    fetchPriority?: "high" | "low" | "auto";
    onInfoPanelToggleClick: (event: React.MouseEvent) => void;
    onOfferLinkClick?: () => void;
    onPrevClick?: (slideIndex: number) => void;
    onNextClick?: (slideIndex: number) => void;
}

export const OfferBoxTopSectionSlider = (props: IProps) => {
    const {fetchPriority, offer, onInfoPanelToggleClick} = props;
    const {isMobile} = useUserDevice();
    const mainImageMobile = offer.main_image?.m_img_375x211;
    const mainImageDesktop = offer.main_image && "m_img_500" in offer.main_image ? offer.main_image?.m_img_500 : offer.main_image?.m_img_750;
    const mainImage = (isMobile ? mainImageMobile : mainImageDesktop) || (isOfferPreSale(offer) ? presaleOfferDefaultImg : undefined);
    const mapImage = "map_image" in offer ? offer.map_image : undefined;
    const gallery = useMemo(() => {
        return "gallery" in offer && offer.gallery ? offer.gallery.map((record) => record?.image).filter(Boolean) : [];
    }, [offer.id]);
    const isMounted = useIsMounted();

    const openResponsiveOfferLink = useResponsiveOpenLink(props.offerLink);
    const handleOfferLinkClick = () => {
        props.onOfferLinkClick?.();
        openResponsiveOfferLink();
    };

    return (
        <div css={offerBoxTopSection} title={offer.name}>
            <SimpleSlider
                leftArrow={({currentSlide}) => currentSlide > 1 && <SimpleSlider.ArrowLeft />}
                rightArrow={({currentSlide, totalSlides}) => currentSlide < totalSlides && <SimpleSlider.ArrowRight />}
                onPrevClick={props.onPrevClick}
                onNextClick={props.onNextClick}
                onSlideClick={handleOfferLinkClick}
                lazyLoadOffset={1}
                css={sliderStyle}
            >
                <div css={slideStyle}>
                    <Image
                        src={mainImage}
                        alt={offer.name}
                        css={slideImgWrapperStyle}
                        imageStyle={offerBoxImgStyle}
                        width="375px"
                        height={isMobile ? "211px" : "246px"}
                        loading={props.index === 0 ? undefined : "lazy"}
                        fetchPriority={fetchPriority}
                        onClick={handleOfferLinkClick}
                    />

                    <div css={favouriteIcon}>
                        <SubscribeButton offerId={offer.id} />
                    </div>

                    {getOfferBoxBadge(props.offer)}

                    {offer.description && (
                        <InfoIcon onClick={onInfoPanelToggleClick} css={infoIcon} size="1.6" wrapperType="circle" wrapperColor="white" wrapperSize="2.2" />
                    )}
                </div>

                {mapImage ? (
                    <div css={slideStyle}>
                        {isMounted && (
                            <OfferBoxTopSectionMap
                                src={isMobile ? mapImage?.m_img_375x211 : mapImage?.m_img_375x256}
                                src2x={isMobile ? mapImage?.m_img_760x428 : mapImage?.m_img_760x520}
                                src3x={isMobile ? mapImage?.m_img_1125x633 : mapImage?.m_img_1125x769}
                                alt={`${offer.name} - mapa`}
                                css={slideImgWrapperStyle}
                                imageStyle={offerBoxImgStyle}
                                onClick={handleOfferLinkClick}
                            />
                        )}
                    </div>
                ) : (
                    [] // empty array instead of null for React.Children.count to work correctly
                )}

                {gallery.map((galleryImg, index) => (
                    <div key={galleryImg?.g_img_372x210} css={slideStyle}>
                        <Image
                            src={isMobile ? galleryImg?.g_img_372x210 : galleryImg?.g_img_760x428}
                            src2x={galleryImg?.g_img_760x428}
                            src3x={galleryImg?.g_img_1500}
                            alt={`${offer.name} - zdjęcie ${index + 1}`}
                            css={slideImgWrapperStyle}
                            imageStyle={offerBoxImgStyle}
                            width="375px"
                            height={isMobile ? "211px" : "246px"}
                            loading="lazy"
                            fetchPriority="low"
                            onClick={handleOfferLinkClick}
                        />
                    </div>
                ))}
            </SimpleSlider>
        </div>
    );
};

const getOfferBoxBadge = (offer: IOfferBoxOffer) => {
    if (hasOfferActivePromotions(offer)) {
        return (
            <Badge css={topBadgeStyle} variant="label_info">
                <Text as="span" variant="info_txt_2">
                    % <strong>Promocje</strong>
                </Text>
            </Badge>
        );
    }

    if (isOfferPreSale(offer)) {
        return (
            <Badge css={topBadgeStyle} variant="label_info">
                <Text as="span" variant="info_txt_2">
                    <strong>Przedsprzedaż</strong>
                </Text>
            </Badge>
        );
    }

    return null;
};

const offerBoxTopSection = css`
    display: block;
    position: relative;

    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;
    }
`;

const INFO_PANEL_PADDING = calculateRemSize(1.5);

const topBadgeStyle = css`
    position: absolute;
    top: ${INFO_PANEL_PADDING};
    left: ${INFO_PANEL_PADDING};
`;

const favouriteIcon = css`
    position: absolute;
    top: ${INFO_PANEL_PADDING};
    right: ${INFO_PANEL_PADDING};
`;

const infoIcon = css`
    position: absolute;
    bottom: ${INFO_PANEL_PADDING};
    right: ${INFO_PANEL_PADDING};
    z-index: 5;
    cursor: pointer;
`;

const sliderStyle = css`
    overflow: hidden;
    ${borderRadius(2, 2, 0, 0)};
`;

const slideStyle = css`
    position: relative;
    ${offerBoxImgWrapperStyle};
`;

const slideImgWrapperStyle = css`
    display: block;
    ${offerBoxImgWrapperStyle};
    cursor: pointer;
`;
import React, {useMemo} from "react";
import {css} from "@emotion/react";

import {Badge} from "@pg-design/badge-module";
import {borderRadius, calculateRemSize} from "@pg-design/helpers-css-strings";
import {InfoIcon} from "@pg-design/icons-module";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";
import {useIsMounted} from "@pg-mono/hooks";
import {SimpleSlider} from "@pg-mono/simple-slider";
import {useUserDevice} from "@pg-mono/user-device";

import {offerBoxImgStyle, offerBoxImgWrapperStyle} from "../../../atoms/OfferBoxBase";
import {useResponsiveOpenLink} from "../../../hooks/use_responsive_open_link";
import {SubscribeButton} from "../../../real_estate/components/SubscribeButton";
import {IOfferBoxOffer} from "../../types/IOfferBoxOffer";
import {hasOfferActivePromotions} from "../../utils/has_offer_active_promotions";
import {isOfferPreSale} from "../../utils/is_offer_pre_sale";
import {OfferBoxTopSectionMap} from "./OfferBoxTopSectionMap";

const presaleOfferDefaultImg = require("../../images/presale_offer_default_img_375x212.jpg");

interface IProps {
    offer: IOfferBoxOffer;
    offerLink: string;
    index?: number;
    fetchPriority?: "high" | "low" | "auto";
    onInfoPanelToggleClick: (event: React.MouseEvent) => void;
    onOfferLinkClick?: () => void;
    onPrevClick?: (slideIndex: number) => void;
    onNextClick?: (slideIndex: number) => void;
}

export const OfferBoxTopSectionSlider = (props: IProps) => {
    const {fetchPriority, offer, onInfoPanelToggleClick} = props;
    const {isMobile} = useUserDevice();
    const mainImageMobile = offer.main_image?.m_img_375x211;
    const mainImageDesktop = offer.main_image && "m_img_500" in offer.main_image ? offer.main_image?.m_img_500 : offer.main_image?.m_img_750;
    const mainImage = (isMobile ? mainImageMobile : mainImageDesktop) || (isOfferPreSale(offer) ? presaleOfferDefaultImg : undefined);
    const mapImage = "map_image" in offer ? offer.map_image : undefined;
    const gallery = useMemo(() => {
        return "gallery" in offer && offer.gallery ? offer.gallery.map((record) => record?.image).filter(Boolean) : [];
    }, [offer.id]);
    const isMounted = useIsMounted();

    const openResponsiveOfferLink = useResponsiveOpenLink(props.offerLink);
    const handleOfferLinkClick = () => {
        props.onOfferLinkClick?.();
        openResponsiveOfferLink();
    };

    return (
        <div css={offerBoxTopSection} title={offer.name}>
            <SimpleSlider
                leftArrow={({currentSlide}) => currentSlide > 1 && <SimpleSlider.ArrowLeft />}
                rightArrow={({currentSlide, totalSlides}) => currentSlide < totalSlides && <SimpleSlider.ArrowRight />}
                onPrevClick={props.onPrevClick}
                onNextClick={props.onNextClick}
                onSlideClick={handleOfferLinkClick}
                lazyLoadOffset={1}
                css={sliderStyle}
            >
                <div css={slideStyle}>
                    <Image
                        src={mainImage}
                        alt={offer.name}
                        css={slideImgWrapperStyle}
                        imageStyle={offerBoxImgStyle}
                        width="375px"
                        height={isMobile ? "211px" : "246px"}
                        loading={props.index === 0 ? undefined : "lazy"}
                        fetchPriority={fetchPriority}
                        onClick={handleOfferLinkClick}
                    />

                    <div css={favouriteIcon}>
                        <SubscribeButton offerId={offer.id} />
                    </div>

                    {getOfferBoxBadge(props.offer)}

                    {offer.description && (
                        <InfoIcon onClick={onInfoPanelToggleClick} css={infoIcon} size="1.6" wrapperType="circle" wrapperColor="white" wrapperSize="2.2" />
                    )}
                </div>

                {mapImage ? (
                    <div css={slideStyle}>
                        {isMounted && (
                            <OfferBoxTopSectionMap
                                src={isMobile ? mapImage?.m_img_375x211 : mapImage?.m_img_375x256}
                                src2x={isMobile ? mapImage?.m_img_760x428 : mapImage?.m_img_760x520}
                                src3x={isMobile ? mapImage?.m_img_1125x633 : mapImage?.m_img_1125x769}
                                alt={`${offer.name} - mapa`}
                                css={slideImgWrapperStyle}
                                imageStyle={offerBoxImgStyle}
                                onClick={handleOfferLinkClick}
                            />
                        )}
                    </div>
                ) : (
                    [] // empty array instead of null for React.Children.count to work correctly
                )}

                {gallery.map((galleryImg, index) => (
                    <div key={galleryImg?.g_img_372x210} css={slideStyle}>
                        <Image
                            src={isMobile ? galleryImg?.g_img_372x210 : galleryImg?.g_img_760x428}
                            src2x={galleryImg?.g_img_760x428}
                            src3x={galleryImg?.g_img_1500}
                            alt={`${offer.name} - zdjęcie ${index + 1}`}
                            css={slideImgWrapperStyle}
                            imageStyle={offerBoxImgStyle}
                            width="375px"
                            height={isMobile ? "211px" : "246px"}
                            loading="lazy"
                            fetchPriority="low"
                            onClick={handleOfferLinkClick}
                        />
                    </div>
                ))}
            </SimpleSlider>
        </div>
    );
};

const getOfferBoxBadge = (offer: IOfferBoxOffer) => {
    if (hasOfferActivePromotions(offer)) {
        return (
            <Badge css={topBadgeStyle} variant="label_info">
                <Text as="span" variant="info_txt_2">
                    % <strong>Promocje</strong>
                </Text>
            </Badge>
        );
    }

    if (isOfferPreSale(offer)) {
        return (
            <Badge css={topBadgeStyle} variant="label_info">
                <Text as="span" variant="info_txt_2">
                    <strong>Przedsprzedaż</strong>
                </Text>
            </Badge>
        );
    }

    return null;
};

const offerBoxTopSection = css`
    display: block;
    position: relative;

    &:hover,
    &:focus {
        color: inherit;
        text-decoration: none;
    }
`;

const INFO_PANEL_PADDING = calculateRemSize(1.5);

const topBadgeStyle = css`
    position: absolute;
    top: ${INFO_PANEL_PADDING};
    left: ${INFO_PANEL_PADDING};
`;

const favouriteIcon = css`
    position: absolute;
    top: ${INFO_PANEL_PADDING};
    right: ${INFO_PANEL_PADDING};
`;

const infoIcon = css`
    position: absolute;
    bottom: ${INFO_PANEL_PADDING};
    right: ${INFO_PANEL_PADDING};
    z-index: 5;
    cursor: pointer;
`;

const sliderStyle = css`
    overflow: hidden;
    ${borderRadius(2, 2, 0, 0)};
`;

const slideStyle = css`
    position: relative;
    ${offerBoxImgWrapperStyle};
`;

const slideImgWrapperStyle = css`
    display: block;
    ${offerBoxImgWrapperStyle};
    cursor: pointer;
`;
