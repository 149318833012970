import React, {PropsWithChildren} from "react";

import {simpleSliderSlide} from "./SimpleSlider.module.css";

interface IProps extends PropsWithChildren {
    slideStyle?: React.CSSProperties;
    width?: number;
}

export const SimpleSliderSlide = (props: IProps) => {
    const {slideStyle, width = {}} = props;

    return (
        <div className={simpleSliderSlide} style={{...slideStyle, width: width ? `${width}px` : "100%"}}>
            {props.children}
        </div>
    );
};
import React, {PropsWithChildren} from "react";

import {simpleSliderSlide} from "./SimpleSlider.module.css";

interface IProps extends PropsWithChildren {
    slideStyle?: React.CSSProperties;
    width?: number;
}

export const SimpleSliderSlide = (props: IProps) => {
    const {slideStyle, width = {}} = props;

    return (
        <div className={simpleSliderSlide} style={{...slideStyle, width: width ? `${width}px` : "100%"}}>
            {props.children}
        </div>
    );
};
