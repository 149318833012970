import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const ChevronLeftIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M8.25 9.333 4.082 5.167 8.25 1H6.167L2 5.167l4.166 4.166H8.25Z" />
        </SvgIcon>
    );
};
import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const ChevronLeftIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M8.25 9.333 4.082 5.167 8.25 1H6.167L2 5.167l4.166 4.166H8.25Z" />
        </SvgIcon>
    );
};
