import {useEffect} from "react";

import {ISimpleSliderProps} from "../types/ISimpleSliderProps";

type IProps = Pick<ISimpleSliderProps, "onSlideChange"> & {
    currentSlideIndex: number;
};

export const useSimpleSliderNotification = (props: IProps) => {
    const {onSlideChange, currentSlideIndex} = props;

    useEffect(() => {
        if (onSlideChange) {
            onSlideChange(currentSlideIndex);
        }
    }, [currentSlideIndex, onSlideChange]);
};
import {useEffect} from "react";

import {ISimpleSliderProps} from "../types/ISimpleSliderProps";

type IProps = Pick<ISimpleSliderProps, "onSlideChange"> & {
    currentSlideIndex: number;
};

export const useSimpleSliderNotification = (props: IProps) => {
    const {onSlideChange, currentSlideIndex} = props;

    useEffect(() => {
        if (onSlideChange) {
            onSlideChange(currentSlideIndex);
        }
    }, [currentSlideIndex, onSlideChange]);
};
