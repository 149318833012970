import React from "react";
import classNames from "classnames";

import {useSimpleSlider} from "../hooks/use_simple_slider";
import {useSimpleSliderNotification} from "../hooks/use_simple_slider_notification";
import {useSimpleSliderSwipe} from "../hooks/use_simple_slider_swipe";
import {ISimpleSliderProps} from "../types/ISimpleSliderProps";
import {SimpleSliderArrowLeft} from "./SimpleSliderArrowLeft";
import {SimpleSliderArrowRight} from "./SimpleSliderArrowRight";
import {SimpleSliderTrack} from "./SimpleSliderTrack";

import {
    simpleSliderArrowWrapper,
    simpleSliderArrowWrapperLeft,
    simpleSliderArrowWrapperRight,
    simpleSliderContainer,
    simpleSliderWrapper
} from "./SimpleSlider.module.css";

export const SimpleSlider = (props: ISimpleSliderProps) => {
    const {children, disableMobileSwipe, maxAllowedOutsideSwipe, leftArrow, rightArrow, onSlideChange} = props;

    const {sliderTrackRef, sliderRef, slideWidth, totalSlides, currentSlideIndex, nextSlide, prevSlide} = useSimpleSlider(props);

    useSimpleSliderNotification({
        currentSlideIndex,
        onSlideChange
    });

    const swipeProps = useSimpleSliderSwipe({
        slideWidth,
        totalSlides,
        currentSlideIndex,
        maxAllowedOutsideSwipe,
        disableMobileSwipe,
        prevSlide,
        nextSlide,
        onSlideClick: props.onSlideClick
    });

    const handlePrevClick = () => {
        const slideIndex = currentSlideIndex - 1 || 0;

        prevSlide();
        props.onPrevClick?.(slideIndex);
    };

    const handleNextClick = () => {
        const slideIndex = currentSlideIndex + 1 || totalSlides - 1;

        nextSlide();
        props.onNextClick?.(slideIndex);
    };

    const wrapperClasses = classNames(simpleSliderWrapper, props.className);
    const arrowLeftClasses = classNames(simpleSliderArrowWrapper, simpleSliderArrowWrapperLeft);
    const arrowRightClasses = classNames(simpleSliderArrowWrapper, simpleSliderArrowWrapperRight);

    return (
        <div ref={sliderRef} className={wrapperClasses}>
            <div
                onTouchStart={swipeProps.handleTouchStart}
                onTouchMove={swipeProps.handleTouchMove}
                onTouchEnd={swipeProps.handleTouchEnd}
                className={simpleSliderContainer}
            >
                <SimpleSliderTrack
                    ref={sliderTrackRef}
                    {...props}
                    currentIndex={currentSlideIndex}
                    totalSlides={totalSlides}
                    isSwiping={swipeProps.isSwiping}
                    slideWidth={slideWidth}
                    translateX={swipeProps.translateX}
                >
                    {children}
                </SimpleSliderTrack>
            </div>
            {leftArrow ? (
                <div className={arrowLeftClasses} onClick={handlePrevClick} role="button" aria-label="Poprzedni slajd">
                    {leftArrow({currentSlide: currentSlideIndex + 1, totalSlides})}
                </div>
            ) : null}
            {rightArrow ? (
                <div className={arrowRightClasses} onClick={handleNextClick} role="button" aria-label="Następny slajd">
                    {rightArrow({currentSlide: currentSlideIndex + 1, totalSlides})}
                </div>
            ) : null}
        </div>
    );
};

SimpleSlider.ArrowLeft = SimpleSliderArrowLeft;
SimpleSlider.ArrowRight = SimpleSliderArrowRight;
import React from "react";
import classNames from "classnames";

import {useSimpleSlider} from "../hooks/use_simple_slider";
import {useSimpleSliderNotification} from "../hooks/use_simple_slider_notification";
import {useSimpleSliderSwipe} from "../hooks/use_simple_slider_swipe";
import {ISimpleSliderProps} from "../types/ISimpleSliderProps";
import {SimpleSliderArrowLeft} from "./SimpleSliderArrowLeft";
import {SimpleSliderArrowRight} from "./SimpleSliderArrowRight";
import {SimpleSliderTrack} from "./SimpleSliderTrack";

import {
    simpleSliderArrowWrapper,
    simpleSliderArrowWrapperLeft,
    simpleSliderArrowWrapperRight,
    simpleSliderContainer,
    simpleSliderWrapper
} from "./SimpleSlider.module.css";

export const SimpleSlider = (props: ISimpleSliderProps) => {
    const {children, disableMobileSwipe, maxAllowedOutsideSwipe, leftArrow, rightArrow, onSlideChange} = props;

    const {sliderTrackRef, sliderRef, slideWidth, totalSlides, currentSlideIndex, nextSlide, prevSlide} = useSimpleSlider(props);

    useSimpleSliderNotification({
        currentSlideIndex,
        onSlideChange
    });

    const swipeProps = useSimpleSliderSwipe({
        slideWidth,
        totalSlides,
        currentSlideIndex,
        maxAllowedOutsideSwipe,
        disableMobileSwipe,
        prevSlide,
        nextSlide,
        onSlideClick: props.onSlideClick
    });

    const handlePrevClick = () => {
        const slideIndex = currentSlideIndex - 1 || 0;

        prevSlide();
        props.onPrevClick?.(slideIndex);
    };

    const handleNextClick = () => {
        const slideIndex = currentSlideIndex + 1 || totalSlides - 1;

        nextSlide();
        props.onNextClick?.(slideIndex);
    };

    const wrapperClasses = classNames(simpleSliderWrapper, props.className);
    const arrowLeftClasses = classNames(simpleSliderArrowWrapper, simpleSliderArrowWrapperLeft);
    const arrowRightClasses = classNames(simpleSliderArrowWrapper, simpleSliderArrowWrapperRight);

    return (
        <div ref={sliderRef} className={wrapperClasses}>
            <div
                onTouchStart={swipeProps.handleTouchStart}
                onTouchMove={swipeProps.handleTouchMove}
                onTouchEnd={swipeProps.handleTouchEnd}
                className={simpleSliderContainer}
            >
                <SimpleSliderTrack
                    ref={sliderTrackRef}
                    {...props}
                    currentIndex={currentSlideIndex}
                    totalSlides={totalSlides}
                    isSwiping={swipeProps.isSwiping}
                    slideWidth={slideWidth}
                    translateX={swipeProps.translateX}
                >
                    {children}
                </SimpleSliderTrack>
            </div>
            {leftArrow ? (
                <div className={arrowLeftClasses} onClick={handlePrevClick} role="button" aria-label="Poprzedni slajd">
                    {leftArrow({currentSlide: currentSlideIndex + 1, totalSlides})}
                </div>
            ) : null}
            {rightArrow ? (
                <div className={arrowRightClasses} onClick={handleNextClick} role="button" aria-label="Następny slajd">
                    {rightArrow({currentSlide: currentSlideIndex + 1, totalSlides})}
                </div>
            ) : null}
        </div>
    );
};

SimpleSlider.ArrowLeft = SimpleSliderArrowLeft;
SimpleSlider.ArrowRight = SimpleSliderArrowRight;
