import React from "react";

import {Text} from "@pg-design/text-module";

import {noOptionsMessage} from "./NoOptionsMessage.module.css";

interface IProps {
    inputValue?: string;
    customText?: string;
}

export const NoOptionsMessage = (props: IProps) => {
    return (
        <div className={noOptionsMessage}>
            {props.inputValue ? (
                <>
                    <Text mb="0.8rem" variant="body_copy_1">
                        Niestety nic nie znaleźliśmy
                    </Text>
                    <Text variant="info_txt_2">Zmień parametry wyszukiwania</Text>
                </>
            ) : (
                <Text variant="body_copy_1">{props.customText || "Wpisz wartość..."}</Text>
            )}
        </div>
    );
};
import React from "react";

import {Text} from "@pg-design/text-module";

import {noOptionsMessage} from "./NoOptionsMessage.module.css";

interface IProps {
    inputValue?: string;
    customText?: string;
}

export const NoOptionsMessage = (props: IProps) => {
    return (
        <div className={noOptionsMessage}>
            {props.inputValue ? (
                <>
                    <Text mb="0.8rem" variant="body_copy_1">
                        Niestety nic nie znaleźliśmy
                    </Text>
                    <Text variant="info_txt_2">Zmień parametry wyszukiwania</Text>
                </>
            ) : (
                <Text variant="body_copy_1">{props.customText || "Wpisz wartość..."}</Text>
            )}
        </div>
    );
};
