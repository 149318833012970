import {IOfferDetail} from "../types/IOfferDetail";

type IOffer = Pick<IOfferDetail, "geo_point" | "id" | "name" | "type"> & {
    region: Pick<IOfferDetail["region"], "country" | "full_name" | "id">;
};

export const createTrackedOffer = (offer: IOffer) => {
    return {
        geo_point: {
            coordinates: offer.geo_point.coordinates
        },
        id: offer.id,
        name: offer.name,
        type: offer.type,
        region: {
            country: offer.region.country,
            full_name: offer.region.full_name,
            id: offer.region.id
        }
    };
};
import {IOfferDetail} from "../types/IOfferDetail";

type IOffer = Pick<IOfferDetail, "geo_point" | "id" | "name" | "type"> & {
    region: Pick<IOfferDetail["region"], "country" | "full_name" | "id">;
};

export const createTrackedOffer = (offer: IOffer) => {
    return {
        geo_point: {
            coordinates: offer.geo_point.coordinates
        },
        id: offer.id,
        name: offer.name,
        type: offer.type,
        region: {
            country: offer.region.country,
            full_name: offer.region.full_name,
            id: offer.region.id
        }
    };
};
