// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

interface IDesktopText {
    variant: "headline_3" | "headline_6" | "body_copy_1" | "body_copy_2" | "info_txt_1" | "info_txt_2" | "info_txt_3";
    strong?: boolean;
    fontFamily?: "primary" | "secondary";
}

// DesktopText is a typography component that will not scale down on mobile and will retain same size. This component is based on @pg-design/text component however it breaks the rules of design system.
export const DesktopText = styled.span<IDesktopText>`
    margin: 0;
    font-family: ${({theme, fontFamily = "primary"}) =>
        fontFamily === "primary" ? theme?.text?.family?.primary ?? "'Poppins', sans-serif" : theme?.text?.family?.secondary ?? "'Playfair_Display', serif"};

    ${(props) => {
        switch (props.variant) {
            case "headline_3": {
                return css`
                    font-size: 2.523rem;
                    line-height: 3.2rem;
                    font-weight: 400;
                `;
            }
            case "headline_6": {
                return css`
                    font-size: 1.772rem;
                    line-height: 2.4rem;
                    font-weight: 500;
                `;
            }
            case "body_copy_1": {
                return css`
                    font-size: 1.575rem;
                    line-height: 2.4rem;
                    font-weight: ${props.strong ? 700 : 400};
                `;
            }

            case "body_copy_2": {
                return css`
                    font-size: 1.4rem;
                    line-height: 2rem;
                    font-weight: ${props.strong ? 700 : 400};
                `;
            }
            case "info_txt_1": {
                return css`
                    font-size: 1.244rem;
                    line-height: 1.6rem;
                    font-weight: 500;
                `;
            }
            case "info_txt_2": {
                return css`
                    font-size: 1.106rem;
                    line-height: 1.2rem;
                    font-weight: 400;
                `;
            }
            case "info_txt_3": {
                return css`
                    font-size: 0.983rem;
                    line-height: 1.2rem;
                    font-weight: 400;
                `;
            }
        }
    }}
`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

interface IDesktopText {
    variant: "headline_3" | "headline_6" | "body_copy_1" | "body_copy_2" | "info_txt_1" | "info_txt_2" | "info_txt_3";
    strong?: boolean;
    fontFamily?: "primary" | "secondary";
}

// DesktopText is a typography component that will not scale down on mobile and will retain same size. This component is based on @pg-design/text component however it breaks the rules of design system.
export const DesktopText = styled.span<IDesktopText>`
    margin: 0;
    font-family: ${({theme, fontFamily = "primary"}) =>
        fontFamily === "primary" ? theme?.text?.family?.primary ?? "'Poppins', sans-serif" : theme?.text?.family?.secondary ?? "'Playfair_Display', serif"};

    ${(props) => {
        switch (props.variant) {
            case "headline_3": {
                return css`
                    font-size: 2.523rem;
                    line-height: 3.2rem;
                    font-weight: 400;
                `;
            }
            case "headline_6": {
                return css`
                    font-size: 1.772rem;
                    line-height: 2.4rem;
                    font-weight: 500;
                `;
            }
            case "body_copy_1": {
                return css`
                    font-size: 1.575rem;
                    line-height: 2.4rem;
                    font-weight: ${props.strong ? 700 : 400};
                `;
            }

            case "body_copy_2": {
                return css`
                    font-size: 1.4rem;
                    line-height: 2rem;
                    font-weight: ${props.strong ? 700 : 400};
                `;
            }
            case "info_txt_1": {
                return css`
                    font-size: 1.244rem;
                    line-height: 1.6rem;
                    font-weight: 500;
                `;
            }
            case "info_txt_2": {
                return css`
                    font-size: 1.106rem;
                    line-height: 1.2rem;
                    font-weight: 400;
                `;
            }
            case "info_txt_3": {
                return css`
                    font-size: 0.983rem;
                    line-height: 1.2rem;
                    font-weight: 400;
                `;
            }
        }
    }}
`;
