import React, {ComponentType} from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {borderRadius, calculateRemSize, flexAbsoluteCenter, p} from "@pg-design/helpers-css";
import {IIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

interface IProps {
    icon: ComponentType<IIcon>;
    text: string;
    isArchived?: boolean;
}

export const OfferBoxPill = (props: IProps) => {
    const theme = useTheme();
    const Icon = props.icon;

    return (
        <div css={wrapperStyle}>
            {!props.isArchived && (
                <div css={iconWrapperStyle}>
                    <Icon size="1.6" />
                </div>
            )}
            <Text variant="info_txt_1" color={theme.colors.secondary} strong>
                {props.isArchived ? "Oferta archiwalna" : props.text}
            </Text>
        </div>
    );
};

const wrapperStyle = (theme: Theme) => css`
    ${p(0, 1)};
    display: inline-flex;
    align-items: center;
    background-color: ${theme.colors.gray[200]};
    ${borderRadius(1)};
    gap: ${calculateRemSize(1)};
    height: ${calculateRemSize(3)};
    user-select: none;
`;

const iconWrapperStyle = css`
    ${flexAbsoluteCenter};
    width: ${calculateRemSize(2)};
    height: ${calculateRemSize(2)};
`;
import React, {ComponentType} from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {borderRadius, calculateRemSize, flexAbsoluteCenter, p} from "@pg-design/helpers-css";
import {IIcon} from "@pg-design/icons-module";
import {Text} from "@pg-design/text-module";

interface IProps {
    icon: ComponentType<IIcon>;
    text: string;
    isArchived?: boolean;
}

export const OfferBoxPill = (props: IProps) => {
    const theme = useTheme();
    const Icon = props.icon;

    return (
        <div css={wrapperStyle}>
            {!props.isArchived && (
                <div css={iconWrapperStyle}>
                    <Icon size="1.6" />
                </div>
            )}
            <Text variant="info_txt_1" color={theme.colors.secondary} strong>
                {props.isArchived ? "Oferta archiwalna" : props.text}
            </Text>
        </div>
    );
};

const wrapperStyle = (theme: Theme) => css`
    ${p(0, 1)};
    display: inline-flex;
    align-items: center;
    background-color: ${theme.colors.gray[200]};
    ${borderRadius(1)};
    gap: ${calculateRemSize(1)};
    height: ${calculateRemSize(3)};
    user-select: none;
`;

const iconWrapperStyle = css`
    ${flexAbsoluteCenter};
    width: ${calculateRemSize(2)};
    height: ${calculateRemSize(2)};
`;
