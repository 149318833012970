import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize, p, pointer, truncateMultiline} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons-module";

import {DesktopText} from "../../../atoms/typography/DesktopText";

interface IProps {
    className?: string;
    children: string;
    onClose: (event: React.MouseEvent) => void;
    show?: boolean;
    truncateLine?: number;
}

export const OfferToggleDescription = (props: IProps) => {
    const description = props.children.replace(/[\r\n]+/g, " ").slice(0, 400);

    return (
        <OfferToggleDescriptionBase className={props.className} truncateLine={props.truncateLine} show={props.show}>
            <div css={closeIconWrapper} onClick={props.onClose}>
                <CloseIcon size="2" />
            </div>

            <DesktopText variant="info_txt_1">{description.slice(0, description.lastIndexOf(" ")).trim()}</DesktopText>
        </OfferToggleDescriptionBase>
    );
};

const OfferToggleDescriptionBase = styled.div<{show?: boolean; truncateLine?: number}>`
    display: none;
    ${(props) =>
        props.show &&
        css`
            display: block;
            ${truncateMultiline(props.truncateLine || 7)};
        `}
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: white;
    ${p(2, 4.5, 1.5, 1)};
    max-height: calc(100% - 1.2rem);
    height: 100%;
    overflow: hidden;
`;

const closeIconWrapper = css`
    position: absolute;
    top: ${calculateRemSize(2)};
    right: ${calculateRemSize(1)};
    ${pointer};
`;
import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize, p, pointer, truncateMultiline} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons-module";

import {DesktopText} from "../../../atoms/typography/DesktopText";

interface IProps {
    className?: string;
    children: string;
    onClose: (event: React.MouseEvent) => void;
    show?: boolean;
    truncateLine?: number;
}

export const OfferToggleDescription = (props: IProps) => {
    const description = props.children.replace(/[\r\n]+/g, " ").slice(0, 400);

    return (
        <OfferToggleDescriptionBase className={props.className} truncateLine={props.truncateLine} show={props.show}>
            <div css={closeIconWrapper} onClick={props.onClose}>
                <CloseIcon size="2" />
            </div>

            <DesktopText variant="info_txt_1">{description.slice(0, description.lastIndexOf(" ")).trim()}</DesktopText>
        </OfferToggleDescriptionBase>
    );
};

const OfferToggleDescriptionBase = styled.div<{show?: boolean; truncateLine?: number}>`
    display: none;
    ${(props) =>
        props.show &&
        css`
            display: block;
            ${truncateMultiline(props.truncateLine || 7)};
        `}
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: white;
    ${p(2, 4.5, 1.5, 1)};
    max-height: calc(100% - 1.2rem);
    height: 100%;
    overflow: hidden;
`;

const closeIconWrapper = css`
    position: absolute;
    top: ${calculateRemSize(2)};
    right: ${calculateRemSize(1)};
    ${pointer};
`;
